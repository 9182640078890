<template>
  <div>
    <div>
      <transition name="flixFadeIn">
        <div :key="open">
          <div class="flix-form-group" v-if="!open && !deleted">
            <a href="#" class="flix-html-a flix-html-small" @click.prevent="open = !open">
              <flixIcon id="undo" /> {{ $t('message.restore', {name: $t('message.default', { name: $tc('message.pageBuilder', 1) }) }) }}
            </a>
          </div>
          <div v-if="open">
            <span class="flix-text-danger">{{ $t('message.changesDelete') }}</span> <a href="#" class="flix-btn flix-btn-xs flix-btn-default" @click.prevent="function () { setStandardLayout() }">{{ $t('message.yes') }}</a> <a class="flix-btn flix-btn-xs flix-btn-default" href="#" @click.prevent="open = false">{{ $t('message.no') }}</a>
          </div>
          <transition name="flixFadeIn">
            <div :key="deleted" v-if="deleted">
              <div class="flix-alert flix-alert-success">{{ $t('message.successfullDeleted') }}</div>
            </div>
          </transition>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { standardLayout } from '@/components/pageBuilder/standardLayout.js'
export default {
  components: {},
  props: {
    callback: Function
  },
  data () {
    return {
      open: false,
      deleted: false,
      variables: this.$getUserVariables()
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    setStandardLayout () {
      var data = JSON.stringify(standardLayout)
        .split(':id').join(this.$route.params.id)
        .split(':user').join(this.variables.user.md5_id)

      data = JSON.parse(data)

      var send = {
        user: this.variables.user.md5_id,
        ID: this.$route.params.id,
        data: data
      }

      this.$flix_post({
        url: 'pagebuilder/bookingpage/save',
        data: send,
        callback: function (ret) { this.deleted = true; this.callback() }.bind(this)
      })
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
